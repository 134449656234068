<template>
  <div class="print"></div>
</template>
<script>
import { checkToken } from "@/api/apiData";
export default {
  mounted() {
    this.$bus.emit("navS", 6);
    let that = this
      var token = localStorage.getItem("token");
    checkToken({ token: token }).then((res) => {
      if (res.code == 1) {
        that.$router.push({
          path: "/userInfo",
          query: { type: 1 },
        });
      } else {
        that.$router.push({
          path: "/signLogin",
          query: { type: 1 },
        });
      }
    });
  },
};
</script>